@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  display: block;
  /* iframes are inline by default */
  background: #000;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 77vw;
}

@layer base {
  /* h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    } */
  /* ... */
  th {
    @apply relative;
  }
}

.btn {
  text-transform: unset !important;
}

th,
td {
  padding: 15px !important;
}

.full-width {
  width: 9em;
}

.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.sigCanvas {
  width: 100%;
  border: 1px solid black;
}

.sigImage {
  display: block;
  max-width: 50%;
  max-height: 50px;
  margin: 0 auto;
  border: 1px solid black;
  padding: 2rem;
}
